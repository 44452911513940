<template>
  <div>
    <div class="text-center my-3" v-if="Caricato == false">
      <b-spinner
          variant="primary"
          style="width: 3rem; height: 3rem;"
          class="mr-1"
          label="loading"
      />
    </div>

    <div v-if="NotificaDisattivazione">
      <b-card
        text-variant="center"
        class="card"
      >

        <b-avatar
          variant="warning"
          size="70"
          class="shadow mb-2"
        >
          <feather-icon
            size="28"
            icon="AlertTriangleIcon"
          />
        </b-avatar>
        <h2 class="mb-1 mt-50 text-warning">
          Accesso multi-livello non attivo per la tua azienda
        </h2>
        <b-card-text>
          Se hai più filiali o sub-agenzie e vuoi far fare direttamente gli ordini a loro sull'area riservata,<br /><strong><a href="https://bio-medicalpharma.com/it/contatti" target="_blank"><u>ti invitiamo a contattarci</u></a></strong>, saremo lieti di attivare questa modalità avanzata per la vostra azienda.
        </b-card-text>
      </b-card>
    </div>

    <div v-if="Tabella">
      <b-card bg-variant="Default">
        
        <!-- table -->
        <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :rows="rows"
        :columns="columns"
        :isLoading.sync="isLoading"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            mode: 'pages',
            nextLabel: 'successiva',
            prevLabel: 'precedente',
            rowsPerPageLabel: 'righe per pagina',
            ofLabel: 'di',
            pageLabel: 'pagina', // for 'pages' mode
            perPage: 10
        }"
        styleClass="vgt-table">

          <template slot="loadingContent_stop">
            <div class="bg-primary"> animated loading ....</div>
          </template>

          <div slot="emptystate"> <div class="text-center"> Nessun dato disponibile </div> </div>

          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Ordine -->
            <div
              v-if="props.column.field === 'numero_ordine'"
            >
              <span class="font-weight-bolder">
                {{ props.row.numero_ordine }}
              </span><br />
              <span class="font-small-2 text-muted">
                di {{ props.row.data_inserimento }}
              </span>
            </div>



            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <div class="text-nowrap">
                <feather-icon
                    :id="`tabellaordini-riga-${props.row.id}-riepilogo`"
                    icon="InfoIcon"
                    class="cursor-pointer mr-1"
                    size="21"
                    @click="$router.push({ name: 'azienda-ordine-filiali-info', params: { id: props.row.id, numero_ordine: props.row.numero_ordine }})"
                />
                <b-tooltip
                    title="riepilogo ordine"
                    class="cursor-pointer"
                    :target="`tabellaordini-riga-${props.row.id}-riepilogo`"
                />
              </div>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          
        </vue-good-table>

        <section class="pt-3">
            <div class="demo-inline-spacing pb-3">
                <h6 class="text-muted">Vuoi modificare un ordine inviato da meno di 24 ore ?</h6>
                <b-button
                variant="outline-secondary"
                href="https://bio-medicalpharma.com/it/contatti"
                target="_blank"
                size="sm"
                >
                    <feather-icon
                        icon="PhoneIcon"
                        size="12"
                        class="mr-1"
                    />Contattaci
                </b-button>
            </div>
        </section>

      </b-card>
    </div>

  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { BCard, BAvatar, BCardText, BBadge, BFormGroup, BFormInput, BFormSelect, BButton, BDropdown, BDropdownItem, BTooltip, BSpinner } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,

    BCard,
    BCardText,
    VueGoodTable,
    BButton,
    BTooltip,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  data() {
    return {
      isLoading: true,
      emptystate: true,
      pageLength: 10,
      columns: [
        {
          label: 'N.Ordine',
          field: 'numero_ordine',
          width: '210px',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Filiale',
          field: 'nome_filiale',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Subtotale',
          field: 'subtotale',
          width: '160px',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Totale ordine',
          field: 'totalone',
          width: '160px',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Info',
          field: 'action',
          width: '100px',
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: [
            {
            field: '',
            type: ''
            }
        ],
        page: 1, 
        perPage: 10,
        licenza_pubblica: 'testlicenza'
      },
      userData: {},
      Caricato: false,
      Tabella: false,
      NotificaDisattivazione: false,
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    console.log('created .................... '+this.userData.id_azienda+' |||||||||\nconta_aziende_figlie -> '+this.userData.conta_aziende_figlie)

    if(this.userData.conta_aziende_figlie > 0){
      //ok ci sono aziende figlie -> quindi potrebbero esserci ordini fatti da loro da far vedere
      //this.rows = [];
      this.Caricato = true;
      this.Tabella = true;
      this.loadItems(this.userData.id_azienda);
    } else {
      //azienda senza aziende figlie = messaggio di notifica
      this.Caricato = true;
      this.NotificaDisattivazione = true;
    }    
  },
  methods: {
    updateParams(newProps) {
      //console.log("updateParams --- vvv ")
      //console.log(newProps)
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems(this.userData.id_azienda);
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems(this.userData.id_azienda);
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems(this.userData.id_azienda);
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems(this.userData.id_azienda);
    },

    // load items is what brings back the rows from server
    loadItems(id_azienda) {
        
        //console.log("loadItems ......");
        //console.dir(this.serverParams)
        //this.isLoading = true;

        //this.updateParams({token_user: "fanco-maria-123"})
        
        this.$http.get('v2/azienda/lista_ordini_sub_aziende/'+id_azienda, {
            params: this.serverParams 
            }).then(response => { 
                
                //console.log("risposta vvv"); 
                //console.log(response.data)
                //console.log('--- ---')
                //console.dir(response.data)
                //console.log("response.totalRecords -> "+response.data.totalRecords);
                

                //console.log(response.data.rows)


                this.totalRecords = response.data[0].contarighe;
                this.rows = response.data[1].righe;
                
                
                //rimuovi il loading dopo il caricamento dei dati nella tabella
                this.isLoading = false;            
            })    
    },

  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
